import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=00954282&scoped=true&"
import script from "./AppFooter.vue?vue&type=script&lang=js&"
export * from "./AppFooter.vue?vue&type=script&lang=js&"
import style0 from "./AppFooter.vue?vue&type=style&index=0&id=00954282&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00954282",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SubscribeNewsletter: require('/opt/build/repo/components/elements/SubscribeNewsletter.vue').default})
