//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    showTitle: { type: Boolean, default: false }
  },
  computed: {
    showSenjaCarousel() {
      return process.env.SENJA_HERO_CAROUSEL === 'yes';
    }
  }
};
