import { render, staticRenderFns } from "./marketing-page.vue?vue&type=template&id=5b9463c2&scoped=true&"
import script from "./marketing-page.vue?vue&type=script&lang=js&"
export * from "./marketing-page.vue?vue&type=script&lang=js&"
import style0 from "./marketing-page.vue?vue&type=style&index=0&id=5b9463c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b9463c2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppNav: require('/opt/build/repo/components/AppNav.vue').default,AppTestimonials: require('/opt/build/repo/components/blocks/AppTestimonials.vue').default,AppFeatures: require('/opt/build/repo/components/AppFeatures.vue').default,CallToAction: require('/opt/build/repo/components/elements/CallToAction.vue').default,AppFooter: require('/opt/build/repo/components/AppFooter.vue').default})
