//
//
//
//
//
//
//
//
//
//
//
//

export default {
  head({ $route }) {
    const routeName =
      $route.name.charAt(0).toUpperCase() + $route.name.slice(1);
    return {
      title: `${routeName} | BOMIST`
    };
  }
};
